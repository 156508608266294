.cimg-template-image, .cimg-template-block {

	.cimg-image-vertical-top {
		// image vertically on top = content must be down
		.cimg-content {
			order: 5;
			margin-top: 1em;
			margin-top: var(--margin-content, 1em);
		}
	}

	.cimg-image-vertical-bottom {
		// image vertically on bottom = content must be up
		.cimg-content {
			order: 1;
			margin-bottom: 1em;
			margin-bottom: var(--margin-content, 1em);
		}
	}


	.cimg-content-horizontal-left {

		.cimg-content {
			text-align: left;
			align-self: flex-start;
		}

	}

	.cimg-content-horizontal-center {

		.cimg-content {
			text-align: center;
			align-self: center;
		}

	}


	.cimg-content-horizontal-right {

		.cimg-content {
			text-align: right;
			align-self: flex-end;
		}

	}

}
