.cimg-position-ac {
	text-align: center;
	margin: 2em;
	margin: var(--margin-top, 2em) 0 var(--margin-bottom, 2em) 0;
}

.cimg-position-al {
	text-align: left;
	margin: 2em;
	margin: var(--margin-top, 2em) 0 var(--margin-bottom, 2em) 0;
}

.cimg-position-ar {
	text-align: right;
	margin: 2em;
	margin: var(--margin-top, 2em) 0 var(--margin-bottom, 2em) 0;
}

.cimg-position-fl {
	float: left;
	margin: 2em;
	margin: var(--margin-top, 2em) var(--margin-right, 2em) var(--margin-bottom, 2em) var(--margin-left, 0);
}

.cimg-position-fr {
	float: right;
	margin: 2em;
	margin: var(--margin-top, 2em) var(--margin-right, 0) var(--margin-bottom, 2em) var(--margin-left, 2em);
}

.cimg-position-fl, .cimg-position-fr {
	&:first-child {
		margin-top: var(--margin-top, 0);
	}
	&:last-child {
		margin-bottom: var(--margin-top, 0);
	}
}

@media only screen and (max-width: 550px) {

	.cimg-position-fl, .cimg-position-fr {
		float: none;
	}

	.cimg-position-al, .cimg-position-ac, .cimg-position-ar, .cimg-position-fl, .cimg-position-fr {
	 	margin: var(--margin-top, 2em) 0 var(--margin-bottom, 2em) 0;
	}


}
