.cimg-caption {
	max-width: 60ch;
	color: rgba(black, 0.7);
	color: var(--caption-color, rgba(black, 0.7));
	line-height: var(--line-height, 1.4);
}

.cimg-container-caption-tl, .cimg-container-caption-bl {
	.cimg-caption {
		align-self: flex-start;
		text-align: left;
	}
}

.cimg-container-caption-tc, .cimg-container-caption-bc {
	.cimg-caption {
		align-self: center;
		text-align: center;
	}
}

.cimg-container-caption-tr, .cimg-container-caption-br {
	.cimg-caption {
		align-self: flex-end;
		text-align: right;
	}
}

.cimg-container-caption-tl, .cimg-container-caption-tc, .cimg-container-caption-tr {
	.cimg-caption {
		order: 2;
		margin-bottom: 1em;
		margin-bottom: var(--margin-caption, 1em);
	}
}

.cimg-container-caption-bl, .cimg-container-caption-bc, .cimg-container-caption-br {
	.cimg-caption {
		order: 4;
		margin-top: 1em;
		margin-top: var(--margin-caption, 1em);
	}
}
